import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { Link } from 'react-router-dom'; // Import Link from react-router-dom
import {pattern} from "../assets";

function Copyright() {
  return (
    <Typography variant="body2" >
      {'Copyright © '}
      swiftanook {new Date().getFullYear()}{'.'}
    </Typography>
  );
}


export default function StickyFooter() {
  return (
      <Box
        component="footer"
        sx={{
          px: 2,
          mt: 'auto',
        }} style={{
          background: `linear-gradient(130deg, rgba(19, 19, 32,.6) 55%, rgba(19, 19, 32,.3) 55%), url(${pattern})`, backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: "center",
        }}
        className="custom-shadow"
      >
         
        <Container maxWidth="sm">
        <Typography variant="h6">
          info@swiftanook.com
         </Typography>
         <Typography variant="h6">
         +8801914891364
         </Typography>
         <Typography variant="h6">
         71-75 Shelton St, London WC2H 9JQ, United Kingdom
         </Typography>
         <br/>
         <hr style={{border: "1px solid #6337d4", opacity: "0.2"}}/>
         <br/>
          <Typography variant="body1">
            <Link to="/terms"  style={{color: "#ffffff", textDecoration: "none"}}>
              Terms
            </Link>{' '}
            |{' '}
            <Link to="/privacy"  style={{color: "#ffffff", textDecoration: "none"}}>
              Privacy Policy
            </Link>
          </Typography>
          <Copyright />
          <Typography variant="body1">
          The Services are free online entertainment games, and do not involve any real money bets or prizes. <br/> Games can be addictive. Play responsible. 
          <br/> For 18+.
          </Typography>
        </Container>
      </Box>
  );
}
