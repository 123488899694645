import React from 'react';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles'; // Import makeStyles for styling
import Grow from '@mui/material/Grow';

// Define styles
const useStyles = makeStyles((theme) => ({
  paper: {
    padding: 14, 
    borderRadius: '12px',
    minHeight: '200px', 
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    opacity: "0.8",
    color: "#ffffff",
    background: 'linear-gradient(to left top, #5d54a4, #7d60b2, #9b6dbf, #b97bcb, #d789d7)', 
  },
}));

export default function Benefits() {
  const classes = useStyles(); // Apply the defined styles

  return (
    <Container maxWidth="lg">
      <Typography variant="h4" gutterBottom textAlign="center">Why Choose Us</Typography>
      <Grid container justifyContent="center" spacing={3}>
      <Grow in={true}  timeout={1000} appear={true}>
        <Grid item xs={12} sm={6} md={4}>
          <Paper className={classes.paper}>
            <Typography variant="h5" gutterBottom>
              Vast Game Selection
            </Typography>
            <Typography variant="body1" paragraph>
              Explore a vast and diverse collection of online games, catering to all tastes and preferences. Whether you're into action, strategy, puzzles, or adventures, we have something exciting for every gamer.
            </Typography>
          </Paper>
        </Grid>
      </Grow>

      <Grow in={true}  timeout={1200} appear={true}>
        <Grid item xs={12} sm={6} md={4}>
          <Paper className={classes.paper}>
            <Typography variant="h5" gutterBottom>
              Immersive Gameplay
            </Typography>
            <Typography variant="body1" paragraph>
              Dive into an immersive gaming experience with high-quality graphics, engaging storylines, and seamless gameplay. Our games are designed to keep you entertained for hours on end.
            </Typography>
          </Paper>
        </Grid>
        </Grow>

        <Grow in={true}  timeout={1400} appear={true}>
        <Grid item xs={12} sm={6} md={4}>
          <Paper className={classes.paper}>
            <Typography variant="h5" gutterBottom>
              Community and Competitions
            </Typography>
            <Typography variant="body1" paragraph>
              Join our thriving gaming community and participate in exciting competitions and events. Connect with fellow gamers, showcase your skills, and climb the leaderboards for a chance to win rewards and recognition.
            </Typography>
          </Paper>
        </Grid>
        </Grow>
      </Grid>
    </Container>
  );
}
