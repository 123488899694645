// src/components/CTA.js
import React from "react";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { ctaBg } from "../assets";
import Grow from "@mui/material/Grow";
import { Link } from "react-router-dom";

function CTA() {
  return (
    <div style={{ background: "#101020" }}>
      <Container maxWidth="lg">
        
          {/* Image Block */}
          {/* <img
          src={ctaBg}
          alt="CTA"
          style={{ maxWidth: '400px', width: "100%", height: 'auto' }}
        /> */}
          {/* Text Block */}
          <Grow in={true} timeout={1200} appear={true}>
            <Box mt={3}>
              <Typography variant="h2" textAlign="center" gutterBottom>
                Who We Are
              </Typography>
              <Typography variant="h5" paragraph>
                Swiftanook is more than just an online gaming platform; we're a
                vibrant community of gamers, developers, and creators united by
                a shared passion for gaming. Founded by a team of gaming
                enthusiasts, our mission is to bring you an expansive collection
                of online games that are not only entertaining but also
                challenging, engaging, and, most importantly, fun. From
                fast-paced action games to strategic puzzles, our library is
                diverse, carefully curated, and constantly growing.{" "}
              </Typography>

              <Typography variant="h4" gutterBottom>
                Why We Do It
              </Typography>
              <Typography variant="h5" paragraph>
                At Swiftanook, we believe in the power of games to bring people
                together, challenge our skills, and provide a much-needed escape
                from the everyday. We're driven by the joy that gaming brings to
                our lives and the community we're building. Our platform is
                designed to be a welcoming space for all gamers, whether you're
                a casual player looking for a quick game or a competitive gamer
                seeking new challenges.
              </Typography>

              <Typography variant="h4" gutterBottom>
                Our Mission
              </Typography>
              <Typography variant="h5" paragraph>
                Our mission is simple: to provide a seamless and enjoyable
                gaming experience to players worldwide. We achieve this by
                offering a wide variety of games that are accessible on any
                device, ensuring high-quality gameplay, and fostering a
                supportive and active community.
              </Typography>

              <Button
                variant="contained"
                color="secondary"
                component={Link}
                to="/about"
              >
                Know More
              </Button>
            </Box>
          </Grow>
      </Container>
    </div>
  );
}

export default CTA;
