import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      dark: '#101020',
      main: '#131321',
      main600: '#040407',
      main500: '#08080d',
      main400: '#0b0b14',
      main300: '#0f0f1a',
      main200: '#42424d',
      main100: '#a1a1a6'
    },
    text: {
      primary: '#ffffff',
    },
    secondary: {
      main: '#6337d4',
      secondary: '#27196e'
    },
    success: {
        main: '#C8E4B2',
    }
  },
  typography: {
    fontFamily: 'Varela Round, sans-serif',
    color: "white",
    h1: {
      xs: {fontSize: "1rem"}, 
      md: {fontSize: "5rem"},
    }
  },
  spacing: [0, 4, 8, 16, 32, 64],
});


export default theme;
