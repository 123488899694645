import React from "react";
import Container from "@mui/material/Container";
import Benefits from "./Benefits";
import Header from "./Header";
import Contacts from "./Contacts";
import Typography from "@mui/material/Typography";

export default function About() {
  return (
    <>
      <Header />
      <Container>
        <Typography variant="h2" gutterBottom>
          More about us
        </Typography>

        <Typography variant="h5" gutterBottom>
        Diverse Game Selection:
        </Typography>


        <Typography variant="body1" paragraph>
        Our library spans genres, styles, and levels of complexity. No matter what you're in the mood for, you're sure to find something that suits your taste and skill level.
        </Typography>
        <Typography variant="h5">Quality and Performance</Typography>
        <Typography variant="body1" paragraph>
        We prioritize quality and smooth performance in all our games, ensuring that your gaming experience is the best it can be.
        </Typography>
        <Typography variant="h5">Community-Centric</Typography>

        <Typography variant="body1" paragraph>
        Swiftanook is more than a platform; it's a community. Engage in discussions, participate in events, and connect with fellow gamers.
        </Typography>
        <Typography variant="h5">Always Free</Typography>

        <Typography variant="body1" paragraph>
        Accessibility is key to our mission. All games on Swiftanook are completely free to play. Enjoy endless gaming without ever having to worry about hidden fees or paywalls.
        </Typography>

        <Typography variant="h5" marginTop="3rem">
        Ready to dive into an endless world of gaming? Join the Swiftanook community today and start exploring, playing, and connecting. With new games added regularly and a community of passionate gamers waiting to welcome you, your next great gaming adventure starts here!
        </Typography>
        <Contacts />
      </Container>
    </>
  );
}
