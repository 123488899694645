// src/components/Hero.js
import React from "react";
import Header from "./Header"; // Import your Header component
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import { heroBg, pattern } from "../assets";
import Grow from '@mui/material/Grow';

function Hero() {
  return (
    <Box
      display="flex"
      flexDirection="column"
      style={{
        background: `linear-gradient(to bottom right, rgba(19, 19, 32,.2) 50%, rgba(19, 19, 32,.4) 100%), url(${pattern})`, backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: "center",
        height: {xs: '50vh', md: '95vh'},
      }}
    >

      <Box component="div"
        sx={{
          height: "100%",
          backgroundImage: {xs: 'none', md: `url(${heroBg})`},
          backgroundSize: { xs: '200px', md: '420px' },
          backgroundRepeat: "no-repeat",
          backgroundPosition: "87% top",
        }}
      >
        <Header />
        <Container maxWidth="xl">
        <Grow in={true} timeout={1200}>

          <Box p={4}>
            <Typography variant="h1" sx={{fontSize:{xs: '4rem', sm: '6rem'}}} gutterBottom style={{fontWeight: "900"}}>
              Play online <br/> games <br/> for free
            </Typography>
          
          </Box>
          </Grow>

        </Container>
      </Box>
    </Box>
  );
}

export default Hero;
